var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-tour", {
    attrs: { name: _vm.vuexyTourName, steps: _vm.steps },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (tour) {
          return [
            _c(
              "transition",
              { attrs: { name: "fade" } },
              _vm._l(tour.steps, function (step, index) {
                return tour.currentStep === index
                  ? _c(
                      "v-step",
                      {
                        key: index,
                        attrs: {
                          step: step,
                          "previous-step": tour.previousStep,
                          "next-step": tour.nextStep,
                          stop: tour.stop,
                          "is-first": tour.isFirst,
                          "is-last": tour.isLast,
                          labels: tour.labels,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex justify-center",
                            attrs: { slot: "actions" },
                            slot: "actions",
                          },
                          [
                            tour.currentStep != tour.steps.length - 1
                              ? _c(
                                  "vs-button",
                                  {
                                    staticClass: "mr-3",
                                    attrs: {
                                      size: "small",
                                      "icon-pack": "feather",
                                      icon: "icon-x",
                                      "icon-after": "",
                                      color: "#fff",
                                      type: "border",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.stop(tour)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.$t("pular")) + " ")]
                                )
                              : _vm._e(),
                            tour.currentStep
                              ? _c(
                                  "vs-button",
                                  {
                                    staticClass: "mr-3",
                                    attrs: {
                                      size: "small",
                                      "icon-pack": "feather",
                                      icon: "icon-chevrons-left",
                                      color: "#fff",
                                      type: "border",
                                    },
                                    on: { click: tour.previousStep },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("common.back")) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            tour.currentStep != tour.steps.length - 1
                              ? _c(
                                  "vs-button",
                                  {
                                    staticClass: "btn-tour-next",
                                    attrs: {
                                      size: "small",
                                      "icon-pack": "feather",
                                      icon: "icon-chevrons-right",
                                      "icon-after": "",
                                      color: "#fff",
                                      type: "border",
                                    },
                                    on: { click: tour.nextStep },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("avancar")) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            tour.currentStep == tour.steps.length - 1
                              ? _c(
                                  "vs-button",
                                  {
                                    staticClass: "btn-tour-finish",
                                    attrs: {
                                      size: "small",
                                      "icon-pack": "feather",
                                      icon: "icon-check-circle",
                                      "icon-after": "",
                                      color: "#fff",
                                      type: "border",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.stop(tour)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.stopText) + " ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e()
              }),
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }