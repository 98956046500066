<!-- =========================================================================================
    File Name: VxTour.vue
    Description: Tour Component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <v-tour :name="vuexyTourName" :steps="steps">
        <template slot-scope="tour">
            <transition name="fade">
                <v-step
                    v-if="tour.currentStep === index"
                    v-for="(step, index) of tour.steps"
                    :key="index"
                    :step="step"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels">

                    <div slot="actions" class="flex justify-center">
                        <vs-button
                            size="small"
                            class="mr-3"
                            @click="stop(tour)"
                            icon-pack="feather"
                            icon="icon-x"
                            icon-after
                            color="#fff"
                            type="border"
                            v-if="tour.currentStep != tour.steps.length - 1">
                            {{ $t('pular') }}
                        </vs-button>

                        <vs-button
                            size="small"
                            @click="tour.previousStep"
                            icon-pack="feather"
                            icon="icon-chevrons-left"
                            color="#fff"
                            type="border"
                            class="mr-3"
                            v-if="tour.currentStep">
                            {{ $t('common.back') }}
                        </vs-button>

                        <vs-button
                            size="small"
                            @click="tour.nextStep"
                            icon-pack="feather"
                            icon="icon-chevrons-right"
                            icon-after
                            color="#fff"
                            type="border"
                            class="btn-tour-next"
                            v-if="tour.currentStep != tour.steps.length - 1">
                            {{ $t('avancar') }}
                        </vs-button>

                        <vs-button
                            size="small"
                            @click="stop(tour)"
                            icon-pack="feather"
                            icon="icon-check-circle"
                            icon-after
                            color="#fff"
                            type="border"
                            class="btn-tour-finish"
                            v-if="tour.currentStep == tour.steps.length - 1">
                            {{ stopText }}
                        </vs-button>
                    </div>

                </v-step>
            </transition>
        </template>
    </v-tour>
</template>

<script>
export default{
  name: 'vx-tour',
  props: {
    steps: {
      required: false,
      default: () => { return [] },
      type: Array
    },
    name: {
      required: true,
      type: String
    },
    auto: {
      required: false,
      default: false,
      type: Boolean
    },
    delegate: {
      required: false,
      default: null,
      type: Object
    },
    stopText: {
      default: function () {
        return this.$t('finalizar')
      },
      type: String
    }
  },
  methods: {
    stop(tour) {
      if (tour) {
        tour.stop()
        let tourInfo = localStorage.getItem('tourInfo')
        if (tourInfo) {
          tourInfo = JSON.parse(tourInfo)
        } else {
          tourInfo = {}
        }
        tourInfo[this.name] = true
        localStorage.setItem('tourInfo', JSON.stringify(tourInfo))
      }
    },
    start(forceStart = false) {
      if (this.steps && this.steps.length > 0 && (forceStart || !this.tourStarted(this.name))) {
        this.$tours[this.vuexyTourName].start()
      }
    }
  },
  watch: {
    '$route.path' () {
      this.$tours[this.vuexyTourName].stop()
    }
  },
  beforeMount() {
    if (this.steps.length === 0) {
      const tourSteps = require('@/components/tourSteps')
      this.steps = tourSteps[this.name]

      // Filter steps by breakpoint
      if (this.steps.length > 0) {
        this.steps = this.steps.filter(step => {
          return this.isEmpty(step.breakpoint) || this.satisfyCurrentBreakpoint(step.breakpoint)
        })
      }
    }
  },
  computed: {
    vuexyTourName() {
      return `vuexyTour-${this.name}` 
    }
  },
  mounted () {
    if (this.name) {
      const tourName = `tour-${this.name}`
      this.$root.$on(tourName, () => {
        this.$tours[this.vuexyTourName].start()
      })
      
      if (this.auto) {
        if (!this.tourStarted(this.name)) {
          if (this.delegate && typeof this.delegate === 'function') {
            const delegateResult = this.delegate()
            if (delegateResult === true) {
              setTimeout(() => {
                this.$tours[this.vuexyTourName].start()
              }, 1000)
            }
          } else {
            setTimeout(() => {
              this.$tours[this.vuexyTourName].start()
            }, 1000)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.v-tour {
    .v-step {
        left: -2px !important;
        z-index: 55000;
        background-color: $primary;
        border-radius: .5rem;
        padding: 1.5rem;
        filter: drop-shadow(0 0 7px rgba(0,0,0,.5));

        .v-step__arrow {
            border-color: rgba(var(--vs-primary),1);
        }

        .vs-button-border:not(.btn-tour-next):not(.btn-tour-finish) {
            border-color: rgba(255, 255, 255, .5) !important;
        }

        .vs-button {
            border-radius: 0;
        }
    }
}
</style>
